<template>
  <button
    :disabled="disabled"
    class="mdc-icon-button material-icons rounded-full"
  >
    <slot />
  </button>
</template>

<script>
import { MDCRipple } from '@material/ripple';

export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    iconButtonRipple: null,
  }),

  mounted() {
    this.iconButtonRipple = new MDCRipple(this.$el);
    this.iconButtonRipple.unbounded = true;
  },

  beforeDestroy() {
    this.iconButtonRipple.destroy();
  },
};
</script>
