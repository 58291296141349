<template>
  <div class="max-w-screen-xl mx-auto p-4">
    <mdc-icon
      title="Return to previous page"
      @click.prevent.native="$router.back()"
    >
      arrow_back
    </mdc-icon>
    <div class="grid md:grid-cols-5 gap-4">
      <div class="rounded shadow p-4 bg-white md:col-span-3">
        <div
          id="chromesucks"
          style="position: absolute; opacity: 0; z-index: -10"
        >
          <label for="_email">email</label
          ><input name="_email" type="email" tabindex="-1" />
          <label for="_username">username</label
          ><input name="_username" type="text" tabindex="-1" />
          <label for="_password">password</label
          ><input name="_password" type="password" tabindex="-1" />
        </div>
        <form v-if="email" autocomplete="off" @submit.prevent="testAndSave">
          <mdc-textfield v-model="name" label="Name" class="w-full" />
          <mdc-textfield
            v-model="email"
            label="Email"
            class="w-full"
            disabled
          />
          <!-- <div v-if="socials && socials.length > 0" class="mt-4 ml-3">
            <div class="later-form-label">Social Connections</div>
            <div
              v-for="social in socials"
              :key="social.hash"
              class="flex items-center"
            >
              <later-svg
                :path="`social/${social.type}.svg`"
                class="w-8 text-facebook mr-4"
              />
              <div class="text-sm">
                <div v-text="`Connected as ${social.name}`" />
                <a href="#">Disconnect</a>
              </div>
            </div>
          </div> -->
          <mdc-select
            v-model="mailer"
            :options="$store.state.mailerOptions"
            :keys="{ value: 'id', label: 'label' }"
            required
            label="Email delivery"
            class="w-full"
          />
          <div v-if="mailer && mailer.id === 'smtp'">
            <mdc-textfield
              v-model="server"
              required
              label="Server"
              class="w-full"
            />
            <mdc-textfield
              v-model="port"
              :min="0"
              :max="65535"
              required
              label="Port"
              class="w-full"
              type="number"
            />
            <mdc-select
              v-model="encryption"
              :options="encryptionOptions"
              :keys="{ value: 'id', label: 'label' }"
              required
              label="Encryption"
              class="w-full"
            />
            <mdc-textfield
              v-model="username"
              required
              label="Username"
              class="w-full"
              autocomplete="off"
            />
            <mdc-helpertext
              text="This almost always should be the same as your email address."
            />
            <mdc-textfield
              v-model="password"
              required
              label="Password"
              type="password"
              class="w-full"
              autocomplete="new-password"
              name="chrome-autofill-stinks"
            />
            <mdc-helpertext
              text="Check to see if your provider supports application specific passwords.  If not, then this is the password you use to log in to your email account."
            />
            <mdc-select
              v-model="fallback"
              :options="$store.state.fallbackOptions"
              :keys="{ value: 'id', label: 'label' }"
              required
              label="Fallback Delivery"
              class="w-full"
            />
            <mdc-helpertext
              text="This is the fallback delivery method if we cannot connect to your SMTP server for whatever reason when sending your email."
              type="select"
            />
          </div>
          <div class="my-4 flex">
            <mdc-button
              :disabled="$store.state.loading"
              class="mr-4"
              ripple
              raised
              type="submit"
            >
              Save
            </mdc-button>
            <mdc-button
              v-if="mailer && mailer.id === 'smtp'"
              :disabled="$store.state.loading"
              class="mr-4"
              ripple
              type="button"
              @click.native="testCreds"
            >
              Test Credentials
            </mdc-button>
            <mdc-button
              class="self-end ml-auto"
              danger
              raised
              @click.native="del"
            >
              Delete
            </mdc-button>
          </div>
        </form>
      </div>
      <div
        v-if="mailer"
        class="
          p-4
          rounded
          shadow
          md:col-span-2
          bg-white
          break-words
          leading-normal
        "
      >
        <p v-if="mailer.id === 'unsigned'">
          When you send an email anonymously, your recipients won't see that the
          email was sent by later.io, however your emails can be at an elevated
          risk to get flagged as spam. Also with this method we won't receive
          delivery responses.
        </p>
        <p v-if="mailer.id === 'signed'">
          When we sign and deliver your email, we will log all delivery
          responses from your recipients, so you can always be sure they
          arrived. Depending on the email provider, your recipients may see that
          the email was delivered
          <span class="font-mono text-sm rounded border border-solid px-1"
            >via later.io</span
          >. Gmail adds this, but most others do not.
        </p>
        <template v-if="mailer.id === 'smtp'">
          <p>
            SMTP (simple mail transport protocol) access allows you to have your
            emails sent by your provider.
          </p>
          <p v-html="smtpHelp" />
          <p>
            <span v-text="`${smtpHelp ? 'If you need additional' : 'For'}`" />
            help with finding the settings for your provider, a
            <a
              :href="`https://www.google.com/search?q=${email.substring(
                email.indexOf('@') + 1
              )}+smtp+settings`"
              target="_blank"
              >web search</a
            >
            may be useful.
          </p>
        </template>
        <p>
          See the <router-link to="/faq"> FAQ </router-link> for more
          information on delivery methods.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/utils/xhr';
import { alertDialog, confirmDialog } from '@/utils/dialog';
import MdcButton from '@/components/mdc/button.vue';
import MdcHelpertext from '@/components/mdc/helpertext.vue';
import MdcIcon from '@/components/mdc/icon.vue';
import MdcSelect from '@/components/mdc/select.vue';
import MdcTextfield from '@/components/mdc/textfield.vue';

export default {
  components: {
    MdcButton,
    MdcHelpertext,
    MdcIcon,
    MdcSelect,
    MdcTextfield,
  },

  data: () => ({
    name: null,
    email: null,
    server: null,
    port: null,
    encryption: null,
    username: null,
    password: null,
    socials: null,
    mailer: null,
    fallback: null,
    encryptionOptions: [
      { id: 'tls', label: 'TLS' },
      { id: 'ssl', label: 'SSL' },
    ],
    smtpHelp: null,
  }),

  mounted() {
    axios
      .get(`/account/email/${this.$route.params.hash}/edit`)
      .then((response) => {
        const { account } = response.data;
        this.mailer = this.$store.state.mailerOptions.find(
          (opt) => opt.id === account.mailer
        );
        this.email = account.email;
        this.name = account.name;
        this.socials = account.socials;
        if (account.smtp) {
          this.server = account.smtp.server;
          this.port = account.smtp.port;
          [this.encryption] = this.encryptionOptions.filter(
            (opt) => opt.id === account.smtp.encryption
          );
          this.username = account.smtp.username;
          this.password = account.smtp.password;
          this.fallback = this.$store.state.fallbackOptions.find(
            (opt) => opt.id === account.smtp.fallback
          );
        }
        let { defaults } = response.data;
        if (!defaults) {
          defaults = {
            server: `smtp.${this.email.substring(this.email.indexOf('@') + 1)}`,
            port: 587,
            encryption: 'tls',
          };
        } else this.smtpHelp = defaults.help;
        if (!this.server) this.server = defaults.server;
        if (!this.port) this.port = defaults.port;
        if (!this.encryption)
          [this.encryption] =
            this.encryptionOptions.filter(
              (opt) => opt.id === defaults.encryption
            ) || this.encryptionOptions;
        if (!this.username) this.username = account.email;
        if (!this.password) this.password = '';
        if (!this.fallback)
          this.fallback = this.$store.state.fallbackOptions.find(
            (opt) => opt.id === 'signed'
          );
      });
  },

  methods: {
    testAndSave() {
      this.save(false);
    },
    testCreds() {
      this.save(true);
    },
    save(testonly) {
      axios
        .put(`/account/email/${this.$route.params.hash}`, {
          name: this.name,
          mailer: this.mailer.id,
          server: this.server,
          port: this.port,
          encryption: this.encryption.id,
          username: this.username,
          password: this.password,
          fallback: this.fallback.id,
          testonly,
        })
        .then((response) => {
          if (testonly) alertDialog('Success', 'Credentials are valid!');
          else {
            this.$store.commit('user', response.data);
            this.$store.commit('snackbarMessage', 'Changes saved.');
            this.$router.push('/account/email');
          }
        });
    },
    del() {
      confirmDialog('Delete Account', 'Are you sure?')
        .then(() => {
          axios
            .delete(`/account/email/${this.$route.params.hash}`)
            .then((response) => {
              if (
                response.data.accounts &&
                response.data.accounts.length >= 1
              ) {
                // code duplicated on index.vue
                this.$store.commit('user', response.data);
                this.$store.commit(
                  'snackbarMessage',
                  `Account ${this.email} has been successfully deleted.`
                );
                this.$router.push('/account/email');
              } else {
                this.$store.commit(
                  'snackbarMessage',
                  'Your account has been deleted.'
                );
                this.$store.dispatch('logout', {});
              }
            });
        })
        .catch(() => {});
    },
  },
};
</script>
